import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';
import debounce from 'lodash/debounce';
import updateClinicList from './shared/update_clinic_list.shared';

export default class extends Controller {
  static targets = ['wrapper', 'input'];

  connect() {
    this.onSearching = debounce(this.onSearching, 500);
  }

  onSearching() {
    const url = this.wrapperTarget.dataset.url;
    const searchQuery = this.inputTarget.value;

    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      data: new URLSearchParams({ search_query: searchQuery, searched: true }).toString(),
      success: data => {
        updateClinicList(data);
      }
    });
  }
}
